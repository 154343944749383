.footer {
  padding: 20px;
  background-color: #00000000;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.footer-logo {
  height: 40px;
  width: auto;
} 
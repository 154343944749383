.sidebar-link {
    display: flex !important;
    align-items: center !important  ;
    color: #ffffff !important; /* Text color */
    padding: 10px 15px !important;
    font-size: 16px !important;
    text-decoration: none !important;
    border-radius: 4px !important;
    transition: background-color 0.2s ease;
}

.sidebar-link:hover {
    background-color: #2c2f33 !important; /* Hover background color */
    color: white !important; /* Hover text color */
}

.sidebar-link .icon {
    margin-right: 10px !important;
}

.login-logo {
    will-change: transform;
    backface-visibility: hidden;
    transform: translateZ(0);
}
html {
    --unclass-header-height: 16px;
    --login-header-height: 56px;
    overflow:hidden;
}

.bg-dark {
    background-color: #111217 !important;
}

.btn-dark {
    background-color: #111217 !important;
}
#home-container {
    overflow: hidden;
}

.modal-content {
    background-color: #00000000;
}

.collapse-handle-right {
    margin-right: 11px;
}
.collapse-handle-left {
    margin-left: 11px;
}
.collapse-handle-bottom {
    margin-bottom: 11px;
}
.collapse-handle-top {
    margin-top: 11px;
}

.highlight {
    color: #6c7c93;
}
.highlight:hover {
    color: rgb(255, 193, 7);
}

.highlight-inverse, .highlighted {
    color: rgb(255, 193, 7);
}
.highlight-inverse:hover {
    color: #6c7c93;
}

.panel-control-button {
    padding: 5px;
    height: 30px;
    width: 30px;
    font-size: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 150ms linear color;
}

.pcb-shifted {
    margin-left: auto;
    margin-top: auto;
}

.pcc-container-orient-vertical {
    display: flex;
    width: 62px;
    height: 100%;
}
.pcc-container-orient-horizontal {
    display: flex;
    height: 30px;
}
.panel-control-container {
    display: flex;
}
div .pcc-vertical {
    padding-top: 16px;
    padding-bottom: 16px;
    width: 62px;
    padding-left: 16px;
    padding-right: 16px;
}
.pcc-orient-vertical {
    width: 30px;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
}
.pcc-orient-horizontal {
    flex-direction: row;
    height: 30px;
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    margin-top: auto;
    margin-bottom: auto;
}

.resize-handle {
    flex: 0 0 0.25rem;
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    transition: 150ms linear background-color;
    background-color: rgb(57, 65, 77);
    outline: none;
}

.resize-handle:hover,
.resize-handle[data-resize-handle-active] {
    background-color: rgb(255, 193, 7);
}

.resize-handle-vertical {
    cursor: ns-resize !important;
}
.resize-handle-horizontal {
    cursor: ew-resize !important;
}

@media (pointer: coarse) {
    .resize-handle {
        flex: 0 0 1rem;
    }
}